<template>
    <div class="content-wrapper">
        <section class="content-header p-1 px-2">
            <div class=" div-header container-fluid">
                <div class="d-flex justify-content-between mb-2 up-line">
                    <div class="col-sm-12  align-items-center">
                    <section class="d-flex justify-content-between row mx-0">
                        <h4 class="mt-3 kanban-tittle">
                            <i class="fas fa-border-all"></i>
                            Kanban
                        </h4>
                        <div class="dropdown mt-2">
                            <button class="btn dropdown-toggle mr-5 dropdown-btn" type="button" data-bs-toggle="dropdown">Filtrar
                            <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li class="ml-2 mb-1 mr-2 plan-trial" @click="getCompaniesForPlan('trial')">Trial</li> 
                                <li class="ml-2 mb-1 mr-2 plan-basic" @click="getCompaniesForPlan('basic')">Basico</li> 
                                <li class="ml-2 mb-1 mr-2 plan-essential" @click="getCompaniesForPlan('essential')">Light</li> 
                                <li class="ml-2 mb-1 mr-2 plan-advanced" @click="getCompaniesForPlan('advanced')">Basic</li> 
                                <li class="ml-2 mb-1 mr-2 plan-professional" @click="getCompaniesForPlan('professional')">Pro</li>
                                <hr>
                                <li class="ml-2 mb-1 mr-2 all-plan" @click="getCompanies()">Todas</li>
                            </ul>
                        </div>
                    </section>
                    </div>
                </div>
            </div>
        </section>

      <div class="container-fluid">
          <section class="container-fluid d-flex">
            <input 
                type="text" 
                class="form-control seachCompanies" 
                placeholder="Buscar"
                @keyup="getFilterCompanies()"
                      v-model="searchCompanies"
                      title="Pesquisar Empresa"
                      name="table_search"
            />

               <button type="button" class="btn btn-sm btn-search">
                    <i class="fas fa-search"></i>
                </button>
          </section>
        <div class="board">
            <div class="lane">
                <section class="d-flex justify-content-between">
                    <h2 class="lane-title welcome-tittle">Bem vindos</h2>
                    <b>{{startType.length}}</b>
                </section>
                <Container group-name="kanban" 
                    @drag-start="handleDragStart('start', $event);" 
                    @drop="handleDrop('start', $event)"
                    :get-child-payload="getChildPayload"
                    :drop-placeholder="{className: 'placeholder'}"
                >
                    <Draggable v-for="(card, index) in startType" :key="index">
                        <Card>
                            <button @click="getId(card._id)" @mousedown="getThisId(card._id)" class="btn-modals" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <section class="d-flex justify-content-between">
                                    <section>
                                        <span v-if="card.account_type == 'voip'" class="badge row bg-voip card-voip">Voip</span>
                                        <span v-if="card.account_type == 'basic'" class="badge row bg-info card-basic">Básico</span>
                                        <span v-if="card.account_type == 'essential'" class="badge row bg-success card-essential">Light</span>
                                        <span v-if="card.account_type == 'advanced'" class="badge row bg-primary card-advanced">Basic</span>
                                        <span v-if="card.account_type == 'professional'" class="badge row bg-dark card-professional">Pro</span>
                                        <span v-if="card.account_type == 'premium'" class="badge row bg-warning card-premium">Premium</span>
                                        <span v-if="card.account_type == 'trial'" class="badge row bg-danger card-trial">Trial</span>
                                    </section>

                                    <section>
                                        <span v-if="card.message" class="span-engaged-disengaged card-voip" :class="companyEngagement(card.message) ? 'engaged' : 'disengaged'">
                                            <i class="fa" :class="companyEngagement(card.message) ? 'fa-thumbs-up' : 'fa-thumbs-down'"></i>
                                        </span>
                                        <span v-else class="span-engaged-disengaged disengaged card-voip">
                                            <i class="fa fa-thumbs-down"></i>
                                        </span>
                                    </section>


                                </section>
                                

                                <span class="row"> {{ card.name }} </span>
                                <span class="card-voip"> Último Acesso: </span> <span class="card-voip"> {{ moment(card.last_access_in_platform).format('DD/MM/YYYY') }} </span>
                           </button>
                        </Card>
                    </Draggable>
                </Container>
            </div>
            <div class="lane">
                <section class="d-flex justify-content-between">
                    <h2 class="lane-title onboarding-tittle">Implementação</h2>
                    <b>{{onboardingType.length}}</b>
                </section>
                <Container group-name="kanban"
                    @drag-start="handleDragStart('onboarding', $event, card)" 
                    @drop="handleDrop('onboarding', $event)"
                    :get-child-payload="getChildPayload"
                    :drop-placeholder="{className: 'placeholder'}"
                >

                    <Draggable v-for="(card, index) in onboardingType" :key="index">
                        <Card>
                            <button @click="getId(card._id)" @mousedown="getThisId(card._id)" class="btn-modals" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <section class="d-flex justify-content-between">
                                    <section>
                                        <span v-if="card.account_type == 'voip'" class="badge row bg-voip card-voip">Voip</span>
                                        <span v-if="card.account_type == 'basic'" class="badge row bg-info card-basic">Básico</span>
                                        <span v-if="card.account_type == 'essential'" class="badge row bg-success card-essential">Light</span>
                                        <span v-if="card.account_type == 'advanced'" class="badge row bg-primary card-advanced">Basic</span>
                                        <span v-if="card.account_type == 'professional'" class="badge row bg-dark card-professional">Pro</span>
                                        <span v-if="card.account_type == 'premium'" class="badge row bg-warning card-premium">Premium</span>
                                        <span v-if="card.account_type == 'trial'" class="badge row bg-danger card-trial">Trial</span>
                                    </section>

                                    <section>
                                        <span v-if="card.message" class="span-engaged-disengaged card-voip" :class="companyEngagement(card.message) ? 'engaged' : 'disengaged'">
                                            <i class="fa" :class="companyEngagement(card.message) ? 'fa-thumbs-up' : 'fa-thumbs-down'"></i>
                                        </span>
                                        <span v-else class="span-engaged-disengaged disengaged card-voip">
                                            <i class="fa fa-thumbs-down"></i>
                                        </span>
                                    </section>


                                </section>
                                <span class="row"> {{ card.name }} </span>
                                <span class="card-voip"> Último Acesso: </span> <span class="card-voip"> {{ moment(card.last_access_in_platform).format('DD/MM/YYYY') }} </span>
                           </button>
                        </Card>
                    </Draggable>
                </Container>                
            </div>
            <div class="lane">
                <section class="d-flex justify-content-between">
                    <h2 class="lane-title follow-up-tittle">Acompanhamento</h2>
                    <b>{{followType.length}}</b>
                </section>
                <Container group-name="kanban"
                    @drag-start="handleDragStart('follow-up', $event, card)" 
                    @drop="handleDrop('follow-up', $event)"
                    :get-child-payload="getChildPayload"
                    :drop-placeholder="{className: 'placeholder'}"
                >
                    <Draggable v-for="(card, index) in followType" :key="index">
                        <Card> 
                            <button @click="getId(card._id)" @mousedown="getThisId(card._id)" class="btn-modals" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <section class="d-flex justify-content-between">
                                    <section>
                                        <span v-if="card.account_type == 'voip'" class="badge row bg-voip card-voip">Voip</span>
                                        <span v-if="card.account_type == 'basic'" class="badge row bg-info card-basic">Básico</span>
                                        <span v-if="card.account_type == 'essential'" class="badge row bg-success card-essential">Light</span>
                                        <span v-if="card.account_type == 'advanced'" class="badge row bg-primary card-advanced">Basic</span>
                                        <span v-if="card.account_type == 'professional'" class="badge row bg-dark card-professional">Pro</span>
                                        <span v-if="card.account_type == 'premium'" class="badge row bg-warning card-premium">Premium</span>
                                        <span v-if="card.account_type == 'trial'" class="badge row bg-danger card-trial">Trial</span>
                                    </section>

                                    <section>
                                        <span v-if="card.message" class="span-engaged-disengaged card-voip" :class="companyEngagement(card.message) ? 'engaged' : 'disengaged'">
                                            <i class="fa" :class="companyEngagement(card.message) ? 'fa-thumbs-up' : 'fa-thumbs-down'"></i>
                                        </span>
                                        <span v-else class="span-engaged-disengaged disengaged card-voip">
                                            <i class="fa fa-thumbs-down"></i>
                                        </span>
                                    </section>
                                </section>
                                <span class="row"> {{ card.name }} </span>
                                <span class="card-voip"> Último Acesso: </span> <span class="card-voip"> {{ moment(card.last_access_in_platform).format('DD/MM/YYYY') }} </span>
                           </button>
                        </Card>
                    </Draggable>
                </Container>  
                
            </div>
            <div class="lane">
                <section class="d-flex justify-content-between">
                    <h2 class="lane-title done-tittle">Concluídos</h2>
                    <b>{{doneType.length}}</b>
                </section>
                <Container group-name="kanban"
                    @drag-start="handleDragStart('done', $event, card)" 
                    @drop="handleDrop('done', $event)"
                    :get-child-payload="getChildPayload"
                    :drop-placeholder="{className: 'placeholder'}"
                >
                    <Draggable v-for="(card, index) in doneType" :key="index">
                        <Card> 
                           <button @click="getId(card._id)" @mousedown="getThisId(card._id)" class="btn-modals" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <section class="d-flex justify-content-between">
                                    <section>
                                        <span v-if="card.account_type == 'voip'" class="badge row bg-voip card-voip">Voip</span>
                                        <span v-if="card.account_type == 'basic'" class="badge row bg-info card-basic">Básico</span>
                                        <span v-if="card.account_type == 'essential'" class="badge row bg-success card-essential">Light</span>
                                        <span v-if="card.account_type == 'advanced'" class="badge row bg-primary card-advanced">Basic</span>
                                        <span v-if="card.account_type == 'professional'" class="badge row bg-dark card-professional">Pro</span>
                                        <span v-if="card.account_type == 'premium'" class="badge row bg-warning card-premium">Premium</span>
                                        <span v-if="card.account_type == 'trial'" class="badge row bg-danger card-trial">Trial</span>
                                    </section>

                                    <section>
                                        <span v-if="card.message" class="card-voip span-engaged-disengaged" :class="companyEngagement(card.message) ? 'engaged' : 'disengaged'">
                                            <i class="fa" :class="companyEngagement(card.message) ? 'fa-thumbs-up' : 'fa-thumbs-down'"></i>
                                        </span>
                                        <span v-else class="card-voip span-engaged-disengaged disengaged">
                                            <i class="fa fa-thumbs-down"></i>
                                        </span>
                                    </section>
                                </section>
                                <span class="row"> {{ card.name }} </span>
                                <span class="card-voip"> Último Acesso: </span> <span class="card-voip"> {{ moment(card.last_access_in_platform).format('DD/MM/YYYY') }} </span>
                           </button>
                        </Card>
                    </Draggable>
                </Container>  
            </div>
            <div class="lane">
                <section class="d-flex justify-content-between">
                <h2 class="lane-title paused-tittle">Pausados</h2>
                    <b>{{pausedType.length}}</b>
                </section>
                <Container group-name="kanban"
                    @drag-start="handleDragStart('paused', $event, card)" 
                    @drop="handleDrop('paused', $event)"
                    :get-child-payload="getChildPayload"
                    :drop-placeholder="{className: 'placeholder'}"
                >
                    <Draggable v-for="(card, index) in pausedType" :key="index">
                        <Card>
                            <button @click="getId(card._id)" @mousedown="getThisId(card._id)" class="btn-modals" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <section class="d-flex justify-content-between">
                                    <section>
                                        <span v-if="card.account_type == 'voip'" class="badge row bg-voip card-voip">Voip</span>
                                        <span v-if="card.account_type == 'basic'" class="badge row bg-info card-basic">Básico</span>
                                        <span v-if="card.account_type == 'essential'" class="badge row bg-success card-essential">Light</span>
                                        <span v-if="card.account_type == 'advanced'" class="badge row bg-primary card-advanced">Basic</span>
                                        <span v-if="card.account_type == 'professional'" class="badge row bg-dark card-professional">Pro</span>
                                        <span v-if="card.account_type == 'premium'" class="badge row bg-warning card-premium">Premium</span>
                                        <span v-if="card.account_type == 'trial'" class="badge row bg-danger card-trial">Trial</span>
                                    </section>

                                    <section>
                                        <span v-if="card.message" class="card-voip span-engaged-disengaged" :class="companyEngagement(card.message) ? 'engaged' : 'disengaged'">
                                            <i class="fa" :class="companyEngagement(card.message) ? 'fa-thumbs-up' : 'fa-thumbs-down'"></i>
                                        </span>
                                        <span v-else class="span-engaged-disengaged disengaged card-voip">
                                            <i class="fa fa-thumbs-down"></i>
                                        </span>
                                    </section>
                                </section>
                                <span class="row"> {{ card.name }} </span>
                                <span classs="card-voip"> Último Acesso: </span> <span class="card-voip"> {{ moment(card.last_access_in_platform).format('DD/MM/YYYY') }} </span>
                           </button>
                        </Card>
                    </Draggable>
                </Container>
            </div>

            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                    <div class="modal-body row">

                         <section class="info-company col-12 col-md-7 px-5 pt-4">
                                <section class="d-flex justify-content-between">
                                    {{ company.name }}
                                            <span v-if="company.account_type == 'voip'" class="badge my-1 bg-voip">Voip</span>
                                            <span v-if="company.account_type == 'basic'" class="badge my-1 bg-info">Basic</span>
                                            <span v-if="company.account_type == 'essential'" class="badge my-1 bg-success">Light</span>
                                            <span v-if="company.account_type == 'advanced'" class="badge my-1 bg-primary">Basic</span>
                                            <span v-if="company.account_type == 'professional'" class="badge my-1 bg-dark">Pro</span>
                                            <span v-if="company.account_type == 'premium'" class="badge my-1 bg-warning">Premium</span>
                                            <span v-if="company.account_type == 'trial'" class="badge my-1 bg-danger">Trial</span>
                                </section>
                                <hr>
                            <section class="row mx-0">
                                <section v-if="company.address" class="col-3 d-flex justify-content-end">
                                </section>
                            </section>

                            <section v-if="company.address" class="row mx-0 mt-2">
                                <section class="col-6">
                                    <span class="f-w-900"> Estado </span>  {{ company.address.state }}
                                </section>
                                <section class="col-6">
                                    <span class="f-w-900"> Cidade: </span> {{ company.address.city }}
                                </section>
                            </section>

                            <section class="row mx-0 mt-2">
                                <section class="col-6">
                                    <span class="f-w-900"> Contato Focal: </span>  {{ company.focal_contact }}
                                </section>
                                <section class="col-6">
                                    <span class="f-w-900"> Contato: </span> {{ company.phone }}
                                </section>
                            </section>

                            <section class="row mx-0 mt-2">
                                <section class="col-6">
                                    <span class="f-w-900"> E-mail: </span>  {{ company.email }}
                                </section>
                            </section>

                            <section class="row mx-0 mt-2">
                                <section class="col-6">
                                    <span class="f-w-900"> Data de Criação: </span>  {{ moment(company.created_at).format('DD/MM/YYYY') }}
                                </section>
                                <section class="col-6">
                                    <span class="f-w-900"> Último Acesso: </span> {{ moment(company.last_access_in_platform).format('DD/MM/YYYY') }}
                                </section>
                            </section>

                            <section class="row mx-0 mt-2">
                                <section class="col-6">
                                    <span class="f-w-900"> API Oficial: </span> 
                                    <span v-if="company.whatsapps_integrations_type === 'official'" class="m-0 pb-1">Sim</span>
                                    <span v-if="company.whatsapps_integrations_type === 'tallos'" class="m-0 pb-1">Não</span>
                                </section>
                            </section>

                            <section class="row mx-0 mt-2">
                                <section class="col-6">
                                    <span class="f-w-900"> Último status: </span>
                                    <span v-if="company.stage == 'juridc' && editStage == false" class="m-0 pb-1">Juridico
                                        <button @click="editStage = true" class="btn btn-sm"> <i class="fa fa-pen"></i> </button>
                                    </span> 
                                        
                                        <div v-if="editStage == true" class="form-check">
                                            <input value="juridc" v-model="stageType" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked>
                                            <label class="form-check-label" for="flexRadioDefault1">
                                                Juridico
                                            </label> <br />
                                            <input value="payment" class="form-check-input" v-model="stageType" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                            <label class="form-check-label" for="flexRadioDefault1">
                                                Pagamento
                                            </label> <br />
                                            <input value="ad-implantation" class="form-check-input" v-model="stageType" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                            <label class="form-check-label" for="flexRadioDefault1">
                                                Adoção/Implementação
                                            </label> <br />
                                            <input value="ad-ongoing" class="form-check-input" v-model="stageType" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                            <label class="form-check-label" for="flexRadioDefault1">
                                                Adoção/Onboarding
                                            </label> <br />
                                            <input value="retention" class="form-check-input" v-model="stageType" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                            <label class="form-check-label" for="flexRadioDefault1">
                                                Retenção
                                            </label> <br />
                                            <input value="expansion" class="form-check-input" v-model="stageType" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                            <label class="form-check-label" for="flexRadioDefault1">
                                                Expansão
                                            </label> <br />
                                            <input value="defenders" class="form-check-input" v-model="stageType" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                            <label class="form-check-label" for="flexRadioDefault1">
                                                Defensores
                                            </label> <br />
                                            <button @click="updateStage(company._id);editStage = false" class="btn btn-sm btn-outline-secondary"> <i class="far fa-save"></i> </button>
                                        </div>
                                        
                                    <span v-if="company.stage == 'payment' && editStage == false" class="m-0 pb-1">Pagamento
                                        <button @click="editStage = true" class="btn btn-sm btn-outline-secondary ml-1"> <i class="fa fa-pen"></i> </button>

                                    </span>
                                    <span v-if="company.stage == 'ad-implantation' && editStage == false" class="m-0 pb-1">Adoção/Implementação
                                        <button @click="editStage = true" class="btn btn-sm btn-outline-secondary ml-1"> <i class="fa fa-pen"></i> </button>

                                    </span>
                                    <span v-if="company.stage == 'ad-ongoing' && editStage == false" class="m-0 pb-1">Adoção/Onboarding
                                        <button @click="editStage = true" class="btn btn-sm btn-outline-secondary ml-1"> <i class="fa fa-pen"></i> </button>

                                    </span>
                                    <span v-if="company.stage == 'retention' && editStage == false" class="m-0 pb-1">Retenção
                                        <button @click="editStage = true" class="btn btn-sm btn-outline-secondary ml-1"> <i class="fa fa-pen"></i> </button>

                                    </span>
                                    <span v-if="company.stage == 'expansion' && editStage == false" class="m-0 pb-1">Expansão
                                        <button @click="editStage = true" class="btn btn-sm btn-outline-secondary ml-1"> <i class="fa fa-pen"></i> </button>

                                    </span>
                                    <span v-if="company.stage == 'defenders' && editStage == false" class="m-0 pb-1">Defensores de Marca
                                        <button @click="editStage = true" class="btn btn-sm btn-outline-secondary ml-1"> <i class="fa fa-pen"></i> </button>

                                    </span>
                                </section>
                            </section>
                            <hr>
                            <section class="mb-5">
                                <span class="f-w-900"> Checklists: </span>
                                <i @click="checklist = !checklist" v-if="!checklist" class="style-check fas fa-plus mt-1 ml-1"></i> 
                                <i @click="checklist = !checklist" v-if="checklist" class="style-check fas fa-minus mt-1 ml-1"></i>
                                <section v-if="checklist">
                                    <section class="row d-flex justify-content-between">
                                        <input v-model="contentChecklist" class="mt-2 form-control width-60" type="text" name="" id="">
                                        <select v-model="phaseChecklist" class="form-select form-control col-4 mt-2 width-10" aria-label="Default select example">
                                            <option value="start">Início</option>
                                            <option value="progress">Em andamento</option>
                                            <option value="paused">Aguardando</option>
                                            <option value="done">Concluído</option>
                                        </select>
                                    </section>
                                    <section class="btns-contact d-flex justify-content-start mt-2 mb-4">
                                        <button @click="newChecklist(company._id)" class="btn btn-primary btn-sm mr-2 fwc-900"> Salvar </button>
                                        <button @click="clearChecklist()" class="btn btn-sm btn-clear"> Limpar </button>
                                    </section>
                                </section>
                                <section class="mt-4">
                                    
                                    <section v-for="checklist in resChecklist" :key="checklist.id">

                                        <section v-if="updateNewChecklist == false">
                                            <section class="d-flex justify-content-between mt-2 mb-0 p-2 section-check">
                                                <span>{{ checklist.description[0].content }}</span>
                                                <section>
                                                    <span class="follow-up-tittle f-w-900 mr-1" v-if="checklist.description[0].phases == 'start'"> Início </span>
                                                    <span class="color f-w-900 mr-1" v-if="checklist.description[0].phases == 'progress'"> Em andamento </span>
                                                    <span class="paused-tittle f-w-900 mr-1" v-if="checklist.description[0].phases == 'paused'"> Pausado </span>
                                                    <span class="done-tittle f-w-900 mr-1" v-if="checklist.description[0].phases == 'done'"> Concluído </span>
                                                    <button @click="updateNewChecklist = true" class="btn btn-sm btn-outline-secondary mr-1"> <i class="card-voip fas fa-pen"></i> </button>
                                                    <button @click="removeChecklist(checklist._id); getChecklists(company._id)" class="btn btn-sm btn-outline-danger"> <i class="card-voip fas fa-trash"></i> </button>
                                                </section>
                                            </section>
                                            <span class="fs-12"><span class="f-w-900">Por:</span> {{ checklist.description[0].author }}</span> 
                                            <span class="fs-12"><span class="f-w-900 ml-2">Criação:</span> {{  moment(checklist.description[0].created_at).format('DD/MM/YYYY') }}</span>
                                        </section>

                                        <section v-if="updateNewChecklist == true">
                                            <section class="br-10 d-flex justify-content-between mt-2 mb-0 p-2">
                                                <section class="row d-flex justify-content-between">
                                                    <input :placeholder="checklist.description[0].content" v-model="newContentChecklist" class=" py-0 ml-2 form-control width-60" type="text" name="" id="">
                                                    <select v-model="newPhaseChecklist" class="width-10 form-select form-control col-4 " aria-label="Default select example">
                                                        <option value="start">Início</option>
                                                        <option value="progress">Em andamento</option>
                                                        <option value="paused">Aguardando</option>
                                                        <option value="done">Concluído</option>
                                                    </select>
                                                </section>
                                                <section>
                                                    <button @click="updateChecklist(checklist._id); getChecklists(company._id); updateNewChecklist = false" class="btn btn-sm btn-outline-secondary ml-5 mr-1"> <i class="far fa-save"></i> </button>
                                                    <button @click="updateNewChecklist = false" class="btn btn-sm btn-outline-danger"> <i class="fas fa-times"></i> </button>
                                                </section>
                                            </section>
                                            <span class="fs-12"><span class="f-w-900">Por:</span> {{ checklist.description[0].author }}</span> 
                                            <span class="fs-12"><span class="f-w-900 ml-2">Criação:</span> {{  moment(checklist.description[0].created_at).format('DD/MM/YYYY') }}</span>
                                        </section>

                                    </section>

                                </section>
                            </section>
                        </section>

                        <section class="col-12 col-md-5 phases-cliente pt-4">
                            <div v-if="currentStepOne" class="modal fade" id="modalPrimaryStep" tabindex="-1" role="dialog" aria-labelledby="modalPrimaryStepLabel" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title font-weight-bold" id="modalPrimaryStepLabel">Incluir Etapa Secundária</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <div v-if="companySteps[currentStepOne.name]" class="w-100">
                                                <p class="mb-4">Qual etapa secundária você deseja iniciar?</p>
                                                <div v-for="(stepTwo, index) in Object.entries(companySteps[currentStepOne.name])" :key="index" class="w-100 m-auto">
                                                    <div v-if="!verifyActivesCheck(currentStepOne, stepTwo[0])" class="d-flex mb-4 align-items-center">
                                                        <button
                                                            @click="updateSecondary(currentStepOne.name, stepTwo[0])"
                                                            data-dismiss="modal"
                                                            class="d-flex align-items-center btn btn-sm btn-outline-success mr-2">
                                                                <i class="fas fa-play mr-1 fs-7" ></i>
                                                                <span class="small">Iniciar!</span>
                                                        </button>
                                                        <p class="m-0 p-0">{{ stepTwo[1] }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div>
                                <h6 class="ml-4 mt-2 text-muted">Fase do cliente</h6>
                                <hr />

                                <div class="row phases">
                                    <div class="m-0 p-0 col-12">
                                        <div class="w-100">
                                            <div v-for="(step, index) in currentSteps" :key="index" class="d-flex card m-0 shadow-none p-0 mbi-10" >
                                                <div class="mh-6">
                                                    <div class="d-flex align-items-center justify-content-center h-100">

                                                        <div class="col-11 d-flex flex-wrap align-items-center m-0 p-0 w-100">
                                                            <div class="col-12 m-0 p-0 w-100">
                                                                <div class="d-flex flex-wrap align-items-center">
                                                                    <!-- coluna 01 -->
                                                                    <div class="w-100 col-12 col-lg-6 d-flex flex-wrap align-items-center m-0">
                                                                        <!-- botao iniciar -->
                                                                        <div class="col-12 col-lg-6 m-0 mt-4 mb-2 mb-lg-0 mt-lg-0 p-0 w-100">
                                                                            <!-- botao inicial -->
                                                                            <button @click="initMainStep(step.name)" v-if="!step.info" class="d-flex  btn btn-sm btn-success">
                                                                                <i class="fas fa-play mr-2"></i>
                                                                                <span>Iniciar!</span>
                                                                            </button>

                                                                            <!-- botao concluir -->
                                                                            <button
                                                                                @click="currentStepOne=step"
                                                                                v-else-if="!step.info.finish"
                                                                                type="button"
                                                                                class="d-flex align-items-center btn btn-sm btn-outline-warning"
                                                                                data-toggle="modal"
                                                                                data-target="#modalSecondaryStep">
                                                                                    <i class="far fa-calendar-check mr-2"></i>
                                                                                    <span class="text-dark">Concluir</span>
                                                                            </button>

                                                                            <!-- botao concluido -->
                                                                            <button v-else class="d-flex align-items-center btn btn-sm btn-outline-success" disabled>
                                                                                <i class="fas fa-check mr-2"></i>
                                                                                <span class="text-dark">Concluido!</span>
                                                                            </button>
                                                                        </div> 
                                                                        <div class="col-12 col-lg-8 m-0 p-0">
                                                                            <!-- titulo -->
                                                                            <p class="m-0 p-0 font-weight-bold">{{ translateSteps(step.name) }}</p>
                                                                            <p class="m-0 p-0 small mb-2">{{step.description}}</p>
                                                                            
                                                                            <!-- informações -->
                                                                            <div v-if="step.info" class="p-0 m-0">
                                                                                <!-- informações de start -->   
                                                                                <div v-if="step.info.start" 
                                                                                    class="d-flex align-items-center mr-2 small"
                                                                                    :class="statusControlClass(step).textStart">
                                                                                        <span class="mr-2">{{ `Iniciada por ${step.info.start_by.name}` }}</span>
                                                                                        <i class="far fa-calendar mr-1"
                                                                                            :class="statusControlClass(step).iconStart"></i>
                                                                                        <span>{{step.info ? convertDate(step.info.start) : '' }}</span>
                                                                                </div>

                                                                                <!-- informações de finish -->
                                                                                <div v-if="step.info.finish" 
                                                                                    class="d-flex align-items-center small"
                                                                                    :class="statusControlClass(step).textEnd">
                                                                                        <span class="mr-2">{{ `Finalizada por ${step.info.finish_by.name}` }}</span>
                                                                                        <i class="far fa-check-circle mr-1 text-success"
                                                                                            :class="statusControlClass(step).iconEnd"></i>
                                                                                        <span>{{step.info ? convertDate(step.info.finish) : '' }}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <!-- coluna 02 -->
                                                                    <div v-if="step.info" class="w-100 col-12 col-lg-12 mb-4 mt-3 mt-lg-0">

                                                                        <!-- exibe secundarias se existir etapas ou se a etapa ainda nao foi finalizada -->
                                                                        <p v-if="!step.info.finish &&companySteps[step.name]" class="m-0 p-0 font-weight-bold">Etapas secundárias</p>
                                                                        
                                                                        
                                                                        <!-- renderiza lista de secundaria se existir -->
                                                                        <div v-if="companySteps[step.name]" class="w-100">
                                                                            <div v-for="(stepTwo, index) in startedSecundary(step.name)" :key="index" class="w-100 m-auto">
                                                                                <div class="w-100 mb-3 mt-3">
                                                                                    <!-- botoes -->
                                                                                    <div class="d-flex align-items-center">
                                                                                        <!-- botao concluir -->
                                                                                        <button 
                                                                                            v-if="!stepTwo.finish"
                                                                                            @click="updateSecondary(step.name, stepTwo.name)"
                                                                                            class="btn btn-sm btn-outline-warning pfs-1"
                                                                                            >
                                                                                                Concluir
                                                                                        </button>

                                                                                        <!-- botao de concluido -->
                                                                                        <button 
                                                                                            v-else
                                                                                            disabled
                                                                                            class="btn btn-sm btn-outline-success pfsm-1"
                                                                                            >
                                                                                                concluído
                                                                                        </button>


                                                                                        <p class="m-0 p-0 ml-2 font-weight-bold">{{companySteps[step.name][stepTwo.name]}}</p>
                                                                                    </div>

                                                                                    <!-- descricao -->
                                                                                    <div class="mt-2">


                                                                                        <!-- iniciado -->
                                                                                        <p v-if="stepTwo.start" class="d-flex align-items-center m-0 p-0 small text-muted">
                                                                                            <i class="fas mr-1 fs-6" :class="stepTwo.finish ? 'fa-play' : 'fa-hourglass'"></i>
                                                                                            <span>Iniciada por {{stepTwo.start_by.name}} em {{ convertDate(stepTwo.start) }}</span>
                                                                                        </p>

                                                                                        <!-- finalizado -->
                                                                                        <p v-if="stepTwo.finish" class="d-flex align-items-center m-0 p-0 small text-muted">
                                                                                            <i class="fs-6 fas fa-check mr-1 text-success"></i>
                                                                                            <span>Finalizada por {{stepTwo.finish_by.name}} em {{ convertDate(stepTwo.finish) }}</span>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <!-- Button trigger modal -->
                                                                        <button
                                                                            v-if="!step.info.finish && companySteps[step.name] && checkRemainingSteps(step)" 
                                                                            @click="currentStepOne=step"
                                                                            type="button"
                                                                            class="p17 btn btn-sm btn-primary mt-2 w-50"
                                                                            data-toggle="modal"
                                                                            data-target="#modalPrimaryStep">
                                                                                + adicionar etapa
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5">
                                <h6 class="ml-4 text-muted">Informação de Churn</h6>
                                <hr />

                                <div class="ml-4" v-if="!showChurn && !company.churn_info">
                                    <a class="cp text-primary" @click="initChurn()">+ cadastrar churn</a>
                                </div>

                                <div v-else>
                                    <!-- motivo -->
                                    <div class="row">
                                        <div class="d-flex col-12 align-items-center mt-2">
                                            <div class="mr-2 col-3">
                                                <label for="input-reason" class="word-custom">Motivo</label>
                                            </div>
                                            <div class="form-group m-0 p-0 mb-2 col-9 col-lg-6">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="input-reason"
                                                    placeholder="Motivo geral do churn"
                                                    v-model="company.churn_info.reason">
                                            </div>
                                        </div>
                                    </div>

                                    <!-- descrição -->
                                    <div class="row">
                                        <div class="d-flex col-12 align-items-center mt-2">
                                            <div class="mr-2 col-3">
                                                <label for="input-description" class="word-custom">Descrição</label>
                                            </div>
                                            <div class="form-group m-0 p-0 mb-2 col-9 col-lg-6">
                                                <textarea
                                                    class="form-control"
                                                    id="input-description"
                                                    rows="3"
                                                    placeholder="Descrição mais detalhada"
                                                    v-model="company.churn_info.description"></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- nivel -->
                                    <div class="row">
                                        <div class="d-flex col-12 align-items-center mt-2">
                                            <div class="mr-2 col-3">
                                                <label for="select-level" class="word-custom">Nível do Churn</label>
                                            </div>
                                            <div>
                                                <select id="select-level" class="form-control" v-model="company.churn_info.level">
                                                    <option value="normal">Normal</option>
                                                    <option value="critical">Crítico</option>
                                                    <option value="urgency">Urgência</option>
                                                    <option value="emergency">Emergência</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- informações -->
                                    <div class="row">
                                        <div class="d-flex col-12 align-items-center mt-2">
                                            <div class="text-muted mt-3 ml-2 small">
                                                <p v-if="company.churn_info.created_by" class="m-0 p-0">
                                                    Registrado por {{ company.churn_info.created_by.name }} em {{ convertDate(company.churn_info.created_at) }}
                                                </p>
                                                <p v-if="company.churn_info.edited_by" class="m-0 p-0">
                                                    Editado por {{ company.churn_info.edited_by.name }} em {{ convertDate(company.churn_info.edited_at) }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>



                                    <!-- salvar churn-->
                                    <div class="row mt-5">
                                        <div class="col-lg-12 my-2 d-flex justify-content-center mb-4">
                                            <button @click="showChurn=false" class="btn-big btn btn-default">
                                                Cancelar
                                            </button>
                                            <button class="btn-big btn btn-primary ml-3" @click="createUpdateChurn()">
                                                Salvar Churn
                                            </button>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div v-if="statusCompanySteps.length > 0" class="m-2 mt-2">
                                <h6 class="ml-4 text-muted">Fases</h6>

                                <div class="card-body table-responsive p-0 m70">
                                    <table class="table table-hover table-stripped table-head-fixed">
                                        <thead>
                                                <tr>
                                                    <th>Funcionário</th>
                                                    <th>Etapa</th>
                                                    <th>Início</th>
                                                    <th>Fim</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(step, i) in currentStatusCompanySteps" :key="i">
                                                    <td>{{ step.manager && step.manager.name ? step.manager.name : '...' }}</td>
                                                    <td>{{ step.status_step_one && step.status_step_two ? `${step.status_step_one}/${step.status_step_two}` : step.status_step_one }}</td>
                                                    <td>{{ moment(step.created_at).format('DD/MM/YYYY HH:mm:ss') }}</td>
                                                    <td>{{ step.ended_at ? moment(step.ended_at).format('DD/MM/YYYY HH:mm:ss') : '...' }}</td>
                                                </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </section>

                       

                            <!-- Modal primario -->
                            <div v-if="currentStepOne" class="modal fade" id="modalPrimaryStep" tabindex="-1" role="dialog" aria-labelledby="modalPrimaryStepLabel" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title font-weight-bold" id="modalPrimaryStepLabel">Incluir Etapa Secundária</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <div v-if="companySteps[currentStepOne.name]" class="w-100">
                                                <p class="mb-4">Qual etapa secundária você deseja iniciar?</p>
                                                <div v-for="(stepTwo, index) in Object.entries(companySteps[currentStepOne.name])" :key="index" class="w-100 m-auto">
                                                    <div v-if="!verifyActivesCheck(currentStepOne, stepTwo[0])" class="d-flex mb-4 align-items-center">
                                                        <button
                                                            @click="updateSecondary(currentStepOne.name, stepTwo[0])"
                                                            data-dismiss="modal"
                                                            class="d-flex align-items-center btn btn-sm btn-outline-success mr-2">
                                                                <i class="fas fa-play mr-1 fz-7"></i>
                                                                <span class="small">Iniciar!</span>
                                                        </button>
                                                        <p class="m-0 p-0">{{ stepTwo[1] }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Modal secundario -->
                            <div v-if="currentStepOne" class="modal fade" id="modalSecondaryStep" tabindex="-1" role="dialog" aria-labelledby="modalSecondaryStepLabel" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title font-weight-bold" id="modalSecondaryStepLabel">Encerrar Etapa Principal</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <p>Certifique-se que todas as <strong>Etapas Secundárias ATIVAS</strong>
                                            foram encerradas antes de finalizar a etapa principal.</p>
                                            <p class="font-weight-bold">Você confirma a finalização da etapa?</p>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Fechar</button>
                                            <button @click="finishMainStep(currentStepOne)" type="button" data-dismiss="modal" class="btn btn-primary">Finalizar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                    </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import citiesBasic from "@/assets/scripts/citiesBasic.json";
import states from "@/assets/scripts/states.json";
import segments from '@/assets/scripts/segments.json'
import companySteps from '@/assets/scripts/companySteps.json'
import helpers from '@/assets/helpers'

import moment from 'moment-timezone'
import "moment/locale/pt-br.js"
import Card from './components/Cards'
import { Container, Draggable } from 'vue-smooth-dnd'
import InfoCompany from './components/info_company'
export default {
    components: { 
        Card,
        Container,
        Draggable,
        InfoCompany
    },
    data() {
        return {
            description: true,
            descriptionContent: '',
            contact: false,
            checklist: false,
            editStage: false,
            stageType: '',
            perPage: 600,
            currentPage: 1,

            updateNewChecklist: false,
            phaseChecklist: '',
            contentChecklist: '',
            newPhaseChecklist: '',
            newContentChecklist: '',

            searchCompanies: '',
            companies: [],
            companiesGroup: [],
            keepCurrentDragedCardId: '',

            company: {},
            resChecklist: {},
            churn: {},
            states,
			citiesBasic,
            segments,
			helpers,
            moment,
            totalEmployees: 0,

            statusCompanySteps: {},

            // array para gerar os steps
            defaultSteps: [
                "juridic",
                "payment",
                "adoption",
                "pabx",
                "retention",
                "expansion",
                "brand_defenders",
            ],

            defaultStepsDescription: {
                juridic: "Etapa onde o cliente está fechando contrato",
                payment: "Etapa de pagamento para começar utilização",
                adoption: "Etapa de adoção e adaptação do cliente a plataforma",
                pabx: "Etapa onde ficam os clientes que solicitaram PABX",
                retention: "Etapa de acompanhamento e retenção de cliente",
                expansion: "Etapa de expansão, onde o cliente se encontra consolidado com a plataforma",
                brand_defenders: "Etapa de defensores da marca"
            },

             /* etapas da empresa */
            companySteps,

            /* auxiliar da etapa principal: salvar etapa aqui quando clicar no modal */
            currentStepOne: null,

            /* mostrar cadastro de churn */
            showChurn: false,
            
            cardsIds: '',
            draggingCard: {
                lane: '',
                index: -1,
                cardData: {}
            }
        }
    },

    computed: {
        /**
         * retorna etapas traduzidas
         */
        currentStatusCompanySteps(){
            return this.statusCompanySteps.map(step => {
                const stepOne = step.status_step_one
                const stepTwo = step.status_step_two
                if(stepOne){
                    step.status_step_two = stepTwo && this.companySteps[stepOne] && this.companySteps[stepOne][stepTwo] ?
                        this.companySteps[stepOne][stepTwo] :
                        ''
                    step.status_step_one = this.translateSteps(step.status_step_one)
                }
                return step
            })
        },

        /**
         * populando informações de mudança de fase
         * @returns {Array} array com as informações das etapas
         */
        currentSteps: function(){
            const stepsInfo = this.statusCompanySteps.steps ? this.statusCompanySteps.steps : null
            const steps = this.defaultSteps.map(step => {
                const foundStep = stepsInfo ? stepsInfo.find(stepInfo => step === stepInfo.name) : null
                return {
                    name: step,
                    description: this.defaultStepsDescription[step],
                    info: foundStep ? foundStep : null
                }
            })
            return steps
        },
        startType() {
            return this.companies.filter(start => start.phases == 'start') 
        },
        onboardingType() {
            return this.companies.filter(start => start.phases == 'onboarding') 
        },
        followType() {
            return this.companies.filter(start => start.phases == 'follow-up') 
        },
        doneType() {
            return this.companies.filter(start => start.phases == 'done') 
        },
        pausedType() {
            return this.companies.filter(start => start.phases == 'paused') 
        }
    },
    
    methods: {
        async removeChecklist(idCheck) {            

            this.$tallos_admin_api.defaults.headers.common["Authorization"] = `Bearer ${this.$store.getters.tokenJWT}`;

            await  this.$tallos_admin_api
            .delete(`kanban/remove-checklist/${idCheck}`)
            .then(() => {
                this.$toasted.global.defaultSuccess({msg: `Checklist Removida.`})
                this.getChecklists(id)
            })
            .catch((err) => {
                console.log(err)
            })
        },

        async updateChecklist(idCheck) {

           let description = {
                description:[ {
                    author: this.$store.getters.admin.name,
                    content: this.newContentChecklist,
                    phases: this.newPhaseChecklist
                    }
                ]
            }

            this.$tallos_admin_api.defaults.headers.common["Authorization"] = `Bearer ${this.$store.getters.tokenJWT}`;

            await  this.$tallos_admin_api
            .put(`kanban/update-checklist/${idCheck}`, description)
            .then(() => {
                this.$toasted.global.defaultSuccess({msg: `Checklist Atualizada.`})
                this.getChecklists(id)
            })
            .catch((err) => {
                console.log(err)
            })
        },
        updateStage(id) {
            this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
                `Bearer ${this.$store.getters.tokenJWT}`;
            this.$tallos_admin_api
                .put(`companies/simple/update/${id}/${this.$store.getters.admin.id}`, this.company)
                .then(() => {
                    this.$toasted.global.defaultSuccess({msg: 'Os dados foram atualizados com sucesso.'})
                    // this.companyJourney()
                    // this.getCompanies()
                })
                .catch(() => this.$toasted.global.defaultError({ msg: 'Erro ao atualizar.'}))
        },
        async newChecklist(id) {

            let description = {
                description:[ {
                    author: this.$store.getters.admin.name,
                    content: this.contentChecklist,
                    phases: this.phaseChecklist
                    }
                ]
            }
            

            this.$tallos_admin_api.defaults.headers.common["Authorization"] = `Bearer ${this.$store.getters.tokenJWT}`;

            await  this.$tallos_admin_api
            .post(`kanban/create-checklist/${id}`, description)
            .then(() => {
                this.$toasted.global.defaultSuccess({msg: `Checklist Adicionada.`})
                this.getChecklists(id)
                this.clearChecklist()
            })
            .catch((err) => {
                console.log(err)
                this.$toasted.global.defaultError({msg: 'Verifique os campos'})
            })

            
        },


        clearChecklist() {
            this.contentChecklist = ''
            this.phaseChecklist = ''
            
        },

        getCompaniesForPlan(plan) {
            this.companies = []; 
            this.companiesGroup = [];
            this.getCompaniesStatus = true;

            let url = `companies/account_type/plans?account_type=${plan}&perPage=${this.perPage}&page=${this.currentPage}`

            if (this.searchCompanies) {
                this.perPage = 50;
                this.currentPage = 1;

                url += `&search=${this.searchCompanies}`
            }

            this.$tallos_admin_api.defaults.headers.common["Authorization"] = `Bearer ${this.$store.getters.tokenJWT}`;

            this.$tallos_admin_api.get(url)
                .then(res => {
                this.companies = res.data;
                this.companiesGroup = res.data;
                this.getCompaniesStatus = false;

                this.$toasted.global.defaultSuccess({
                    msg: "Empresas encontradas."
                });
                    })
                    .catch(e => {
                this.$toasted.global.defaultError({
                    msg: "Erro ao buscar empresas."
                });
                });
    },

        saveDescription(id) {
            let newDescription = {
                description:[ {
                    author: this.$store.getters.admin.name,
                    content: this.descriptionContent
                    }
                ]
            }


            this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
				`Bearer ${this.$store.getters.tokenJWT}`;

			this.$tallos_admin_api
				.post(`kanban/create-checklist/${id}`, newDescription)
				.then(res => {
                    this.$toasted.global.defaultSuccess({msg: `Descrição Postada.`})
                })
				.catch(e => {
					this.$toasted.global.defaultError({msg: 'Conteúdo não informado.'})
				})
        },

        selectAccountType() {
			if (this.company.account_type == 'basic') {
				this.company.account_is_trial = false
			}
		},

        companyEngagement(message) {
            return this.moment().diff(this.moment(message.created_at), 'hours') > 24 ? false : true
        },


        translateAccountType(account_type) {
            switch (account_type) {
                case 'trial':
                    return 'Trial'
                case 'voip':
                    return 'Voip'
                case 'premium':
                    return 'Premium'
                case 'basic':
                    return 'Básico'
                case 'essential':
                    return 'Light'
                case 'advanced':
                    return 'Basic'
                case 'professional':
                    return 'Pro'
            }
        },

        translateSteps(step){
            switch (step) {
                case 'payment':
                    return 'Pagamentos'
                case 'adoption':
                    return 'Adoção'
                case 'juridic':
                    return 'Jurídico'
                case 'retention':
                    return 'Retenção'
                case 'expansion':
                    return 'Expansão'
                case 'brand_defenders':
                    return 'Defensores da Marca'
                case 'pabx':
                    return 'PABX'
            }
        },

        /**
        * Metodo para pegar uma data string (2021-02-25T16:26:02.458Z) converte para timestamp e formata usando intl
        * @param {String} date data a ser formatada
        * @return {Date} Retorna uma data formatada como 22/10/1990
        */
        convertDate: function (date) {
            var dateParse = new Date(date).getTime();
            var currentDate = new Intl.DateTimeFormat('pt-BR', {
                // https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                timeZone: 'America/Fortaleza'
            }).format(dateParse)

            return currentDate
        },

        /**
         * Metodo para mudar estilo de acordo com o status
         */
        statusControlClass: function(step){
            if(step.info.start && !step.info.finish){
                return {
                    
                    iconStart: 'text-success',
                    textStart: 'text-dark'
                }
            }
            if(step.info.start && step.info.finish){
                return {
                    iconEnd: 'text-success',
                    iconStart: 'text-muted',
                    textEnd: 'text-dark',
                    textStart: 'text-muted'
                }
            }
        },

        /**
         * Metodo que retorna um array de etapas secundarias ativas
         * @param {String} step nome da etapa principal
         */
        startedSecundary: function(step){
            const foundStep = this.currentSteps.find(curStep => curStep.name === step)
            return foundStep.info.secondary.filter(curStep => curStep.start)
        },

        /**
         * Metodo para checar se todas as etapas foram iniciadas
         * @param {Object} step etapa atual
         */
        checkRemainingSteps: function(step){
            const nonCctives = step.info.secondary.filter(curStep => !curStep.start)
            return nonCctives.length ? true : false
        },


        /**
         * Metodo para iniciar o churn
         */
        initChurn: function(){
            this.showChurn=true
            this.company.churn_info = {}
            this.company.churn_info.reason = ''
            this.company.churn_info.description = ''
            this.company.churn_info.level = ''
        },

        /* ------------ MONTAGEM DE REQUISIÇÃO: PRINCIPAIS METODOS --------------- */

        /**
         * Metodo que recebe o nome da etapa para iniciar.
         * @param {String} step nome da etapa 
         */
        initMainStep: function(step){
            
            /* verifica se possui etapas secundarias. se sim, preenche o array da requisição */
            const secondarySteps = this.companySteps[step] ? Object.keys(this.companySteps[step]) : null
            if(secondarySteps){
                var secondary = secondarySteps.map(stepTwo => ({ name: stepTwo, active: false }))
            }

            /* objeto da requisição */
            const toUpdate = {
                name: step,
                start_by: this.$store.getters.admin.id,
                secondary: secondary ? secondary : []
            }

            this.updateJourneyStep(toUpdate)

        },


        /**
         * Metodo para finalizar a etapa principal
         * @param {Object} step objeto com informações da etapa
         */
        finishMainStep: function(step){
            /* objeto da requisição */
            const toUpdate = {
                name: step.name,
                finish_by: this.$store.getters.admin.id,
            }
            this.updateJourneyStep(toUpdate)
        },


        /**
         * Metodo que recebe as informações para iniciar ou terminar uma sub etapa 
         * @param {String} step nome da etapa principal do cliente
         * @param {String} stepTwoName nome da etapa secundaria
         */
        updateSecondary: function(step, stepTwoName){

            const foundStep = this.currentSteps.find(curStep => curStep.name === step)
            if(!foundStep){
                this.$toasted.global.defaultError({ msg: 'Não foi possivel encontrar a etapa.'})
                return console.debug("Parece que a etapa não foi encontrada na lista. Lista atual >> ", this.currentSteps)
            }

            const foundStepTwo = foundStep.info.secondary.find(curStep => curStep.name === stepTwoName)

            /* se não existe uma etapa secundaria ou ainda nao foi iniciada, é criada/atualizada agora. */
            if(!foundStepTwo || !foundStepTwo.start){
                const toUpdate = {
                    name: step,
                    secondary: {
                        name: stepTwoName,
                        start_by: this.$store.getters.admin.id,
                    }
                }
                return this.updateSecondaryStep(toUpdate)
            }

            /* verifica se já concluiu. */
            if(foundStepTwo.finish){
                this.$toasted.global.defaultError({ msg: 'A etapa já foi concluída'})
                return console.debug("A etapa já foi concluida e não é possivel fazer alterações")
            }

            /* finalizar a etapa */
            const toUpdate = {
                name: step,
                secondary: {
                    name: stepTwoName,
                    finish_by: this.$store.getters.admin.id,
                }
            }
            return this.updateSecondaryStep(toUpdate)

        },


        /**
         * Metodo para montara requisição de churn
         * @param {Object} step objeto com informações do churn
         */
        createUpdateChurn: function(){
            
            /* se ainda não existir churn */
            if(!this.company.churn_info || !this.company.churn_info.created_at){

                /* objeto da requisição */
                const toUpdate = {
                    reason: this.company.churn_info.reason,
                    description: this.company.churn_info.description,
                    level: this.company.churn_info.level,
                    created_by: this.$store.getters.admin.id,
                }
                this.updateChurn(toUpdate)
            } else {

                /* objeto da requisição */
                const toUpdate = {
                    reason: this.company.churn_info.reason,
                    description: this.company.churn_info.description,
                    level: this.company.churn_info.level,
                    edited_by: this.$store.getters.admin.id,
                }
                this.updateChurn(toUpdate)
            }
        },

        /**
         * Metodo para verificar se a etapa ja esta ativa
         */
        verifyActivesCheck: function(stepTwo, name){
            const found = stepTwo.info.secondary.find(step => step.name === name ? step.start : false )
            return found ? true : false
        },

        /* ----------------- REQUISIÇÕES ------------------------- */

        /**
        Metodo para atualizar as informações da empresa
         */
        updateCompany: function() {
            this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
                `Bearer ${this.$store.getters.tokenJWT}`;
            this.$tallos_admin_api
                .put(`companies/simple/update/${this.$route.params.id}/${this.$store.getters.admin.id}`, this.company)
                .then(() => {
                    this.$toasted.global.defaultSuccess({msg: 'Os dados foram atualizados com sucesso.'})
                    this.companyJourney()
                    this.getCompany()
                })
                .catch(() => this.$toasted.global.defaultError({ msg: 'Erro ao atualizar.'}))
        },
        /**
        * Metodo para atualizar uma etapa secundaria
        * @param {Object} toUpdate Objeto contendo informações para atualização
        */
        updateJourneyStep: function(toUpdate){
            this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
                `Bearer ${this.$store.getters.tokenJWT}`;

            this.$tallos_admin_api
                .put(`status-company-steps/update/journey/${this.company._id}`, toUpdate)
                .then(res => {
                    this.$toasted.global.defaultSuccess({msg: 'O status foi atualizado com sucesso.'})
                    console.debug('[jornada] ',res.data.message)
                    this.companyJourney(this.company._id)
                })
                .catch(e => {
                    console.debug('[jornada] Não foi possivel atualizar a etapa principal.', e)
                    this.$toasted.global.defaultError({msg: e.response.data.message})
                })
        },

        /**
         * Chamada da api para atualizar o status da etapa secundaria
         * @param {{name: String, secondary: {name: String, updated_by: String, active: Boolean}}} toUpdate Objeto contendo informações da requisição
         */
        updateSecondaryStep: function(toUpdate) {
            this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
                `Bearer ${this.$store.getters.tokenJWT}`;

            this.$tallos_admin_api
                .put(`status-company-steps/update/secondary/${this.company._id}`, toUpdate)
                .then(res => {
                    this.companyJourney(this.company._id)
                    console.debug('[jornada] ',res.data.message)
                    this.$toasted.global.defaultSuccess({msg: 'Etapa secundária atualizada.'})
                })
                .catch(e => {
                    console.debug('[jornada] Não foi possivel atualizar a etapa secundária.', e)
                    this.$toasted.global.defaultError({msg: 'Não foi possivel atualizar a etapa secundária.'})
                })
        },


         /**
        * Metodo para atualizar o churn
        * @param {Object} toUpdate Objeto contendo informações para atualização
        */
        updateChurn: function(toUpdate){
            this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
                `Bearer ${this.$store.getters.tokenJWT}`;

            this.$tallos_admin_api
                .put(`companies/update/churn/${this.$route.params.id}`, toUpdate)
                .then(res => {
                    this.$toasted.global.defaultSuccess({msg: 'O status foi atualizado com sucesso.'})
                    console.debug('[churn] ', res.data.message)
                    this.getCompany()
                })
                .catch(e => {
                    console.debug('[churn] Não foi possivel atualizar as informações de churn.', e)
                    this.$toasted.global.defaultError({msg: e.response.data.message})
                })
        },

        getTotalEmployees: function() {
            this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
				`Bearer ${this.$store.getters.tokenJWT}`;

			this.$tallos_admin_api
				.get(`employees/count/${this.$route.params.id}`)
				.then(res => this.totalEmployees = res.data)
				.catch(e => this.$toasted.global.defaultError({msg: 'Erro na busca de dados.'}))
        },

        getCompany: function() {
			this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
				`Bearer ${this.$store.getters.tokenJWT}`;

			this.$tallos_admin_api
				.get(`companies/${this.$route.params.id}`)
				.then(res => {
                    this.company = res.data.company
                    this.company['message'] = res.data.last_message
                })
				.catch(e => {
					this.$toasted.global.defaultError({msg: 'Empresa não encontrada.'})
					this.$router.push({ path: '/admin/companies' })
				})
		},

        companyJourney(id) {
            this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
                `Bearer ${this.$store.getters.tokenJWT}`;

            this.$tallos_admin_api
                .get(`status-company-steps/find/journey/${id}`)
                .then(res => this.statusCompanySteps = res.data)
                .catch(e => {
                    console.debug('[jornada] parece que a empresa não tem jornada.', e)
                })
        },
        
        async getId(id) {
            this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
				`Bearer ${this.$store.getters.tokenJWT}`;

			await this.$tallos_admin_api
				.get(`companies/${id}`)
				.then(res => {
                    this.company = res.data.company
                    this.company['message'] = res.data.last_message
                })
				.catch(e => {
					this.$toasted.global.defaultError({msg: 'Empresa não encontrada.'})
					this.$router.push({ path: '/admin/companies' })
				})
                this.getChecklists(id)
                this.companyJourney(id)
        },
        
        async getChecklists(id) {
            this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
				`Bearer ${this.$store.getters.tokenJWT}`;

			await this.$tallos_admin_api
				.get(`kanban/find-checklist/${id}`)
				.then(res => {
                    this.resChecklist = res.data
                })
				.catch(e => {
					this.$toasted.global.defaultError({msg: 'Empresa não encontrada.'})
					this.$router.push({ path: '/admin/companies' })
				})
        },

        async getCompanies() {
            this.companies = []; 
            this.companiesGroup = [];
            this.getCompaniesStatus = true;

            let url = `companies/all`

            this.$tallos_admin_api.defaults.headers.common["Authorization"] = `Bearer ${this.$store.getters.tokenJWT}`;

            await this.$tallos_admin_api.get(url)
                .then(res => {
                    this.companies = res.data;
                    this.companiesGroup = res.data;

                this.$toasted.global.defaultSuccess({
                    msg: "Empresas encontradas."
                });
                    this.getCompaniesStatus = false;
                    })
                    .catch(e => {
                this.$toasted.global.defaultError({
                    msg: "Erro ao buscar empresas."
                });
                });
        },

        getThisId(cardId) {
            console.log(cardId)
            this.keepCurrentDragedCardId = cardId
        },


        handleDragStart(lane, dragResult, card) {

            const { payload, isSource } = dragResult
            if(isSource) {
                this.draggingCard = {
                    lane,
                    index: payload.index
                }
            }
        },
        async handleDrop(lane, dropResult) {
            const { removedIndex, addedIndex} = dropResult
                
                let a = lane 

                if(addedIndex >=0 && addedIndex != null ) {

                    this.$tallos_admin_api.defaults.headers.common["Authorization"] = `Bearer ${this.$store.getters.tokenJWT}`;

                    await  this.$tallos_admin_api
                    .put(`companies/update/kanban/${this.keepCurrentDragedCardId}?phases=${a}`)
                    .then(() => {
                        this.$toasted.global.defaultSuccess({msg: `Etapa Atualizada.`})
                        this.keepCurrentDragedCardId = ''
                        this.getCompanies();
                    })
                    .catch((err) => {
                        console.log(err)
                        this.keepCurrentDragedCardId = ''
                    })
                }

                if(lane === this.draggingCard.lane && removedIndex === addedIndex) {
                    return
                }
        },
        getChildPayload(index) {
            return { 
                index 
            }
        },
        getFilterCompanies() {
            let self = this;

            if (self.searchCompanies == "") {
                self.companies = self.companiesGroup;
            } else {
                self.companies = self.companiesGroup.filter(company => {
                if (company.name && company._id && company.account_type) {
                    return (
                    company.name.match(new RegExp(self.searchCompanies, "i")) ||
                    String(company._id).match(new RegExp(self.searchCompanies, "i")) ||
                    company.account_type.match(new RegExp(self.searchCompanies, "i"))
                    );
                }
                });
            }
        },
        companyEngagement(message) {
		    return moment().diff(moment(message.created_at), 'hours') > 24 ? false : true
	    }
    },
    async created() {
        await this.getCompanies();
        await this.companyJourney();
        document.title = 'Tallos Admin - Informações Empresa'
    }
}
</script>

<style scoped>

.fa-thumbs-down {
    color: #ff6348;
}
.fa-thumbs-up {
    color: #2ed573;
}

.info-company {
    background: #f8f8f8;
}

.btn-search:hover {
    transform: scale(1.2);
}
.btn-search:active {
    border: none;
    outline: none;
}
.btn-modals {
    border: none;
    background: #f8f8f8;
    text-align: left;
}

.btn-modals:focus {
    border: none;
    outline: none;
}
.btn-clear {
 color:#42526E; 
 font-weight:900;
}

.btn-clear:hover {
 background:rgba(9, 30, 66, 0.08);
 color: rgb(66, 82, 110); 
 font-weight:900;
}

.board {
    margin: 2% 0.8rem;
    display: flex;
    max-height: 80vh;
    align-items: flex-start;
	flex-wrap: nowrap;
	overflow-x: scroll;
}

.board::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
    border-radius: 10px;
}

.board::-webkit-scrollbar {
	height: 10px;
	background-color: #F5F5F5;
    
}

.board::-webkit-scrollbar-thumb {
	background-color: #0984e3;
	border-radius: 10px;

	background-image: -webkit-gradient(linear, 0 0, 0 100%,
	                   color-stop(.5, rgba(255, 255, 255, .2)),
					   color-stop(.5, transparent), to(transparent));
}


.lane {
    background: #fff;
    margin: 0 0.8rem;
	height: auto;
    /* border-radius: 0.8rem; */
    padding: 0 0.7rem;
    box-shadow: 0.1rem 0.1rem 0.2rem 0 rgba(33, 33, 33, 0.25);
    min-width: 300px;
    margin-bottom: 15%;
}

.lane-title {
    padding: 0.8rem 0.5rem;
    margin-bottom: 0.6rem;
    font-size: 15px;
    font-weight: 400;
}

.lane h2{
    font-weight: 900;
}

.content-wrapper {
  background-color: #f8f8f8; 
}

.input-search-admin {
    border-radius: 3px !important;
}

.search-admin {
    position: absolute !important;
    top: 8px;
    right: 10px;
    cursor: pointer;
}

.placeholder {
    background: rgba(33, 33, 33, 0.08);
    border-radius: 0.4rem;
    transform: scaleY(0.85);
    transform-origin: 0% 0%;
}
.phases-cliente,
.info-company {
    overflow-y: scroll;
    max-height: 850px;
}

.phases-cliente::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.phases-cliente::-webkit-scrollbar {
	width: 8px;
	background-color: #F5F5F5;
    
}

.phases-cliente::-webkit-scrollbar-thumb {
	background-color: rgb(180, 170, 170);
	border-radius: 10px;
}



.info-company::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.info-company::-webkit-scrollbar {
	width: 8px;
	background-color: #F5F5F5;
    
}

.info-company::-webkit-scrollbar-thumb {
	background-color: rgb(180, 170, 170);
	border-radius: 10px;
}

.phases p{
    font-size: 12px;
}

.dropdown-menu li:hover {
  background: #ddd;
}
.plan-trial,
.plan-basic,
.plan-essential,
.plan-advanced,
.plan-professional,
.all-plan {
    cursor: pointer; 
    font-weight: 900;
}
.plan-trial {
    color:#dc3545; 
}
.plan-basic {
    color: #17a2b8;
}
.plan-essential {
    color: #28a745;
}
.plan-advanced {
    color: #007bff;
}
.plan-professional {
    color: #343a40;
}
.all-plan {
    color: #343a40;
}
.up-line {
    border-bottom: 1px solid #0001;
}
.kanban-tittle {
    color: #0007
}
.dropdown-btn {
    font-weight:900;
}
.welcome-tittle {
    color:#ffa502;
}
.onboarding-tittle {
    color:#2ed573;
}
.follow-up-tittle {
    color:#1e90ff;
}
.done-tittle {
    color:#2ed573;
}
.paused-tittle {
    color:#ff6348;
}
.card-voip,
.card-basic,
.card-essential,
.card-advanced,
.card-professional,
.card-premium,
.card-trial {
    font-size:10px;
}
.f-w-900 {
    font-weight: 900;
}

.section-check{
    background: #ddd;
    border-radius: 10px;
}
.color {
    color: #3742fa;
}
.style-check {
    cursor: pointer;
    color:#0d6efd; 
    font-size: 12px;
}
.seachCompanies {
    width:250px; 
    height:2rem;
}

.width-60 {
    width:60%;
}
.width-10{
    width:10%; 
}
.fwc-900 {
    color:#fff;
    font-weight:900;
}
.fs-12 {
    font-size:12px;
}
.br-10 {
    background: #ddd; 
    border-radius: 10px; 
}
.fs-7 {
    font-size: .7rem;
}
.mbi-10 {
    margin-bottom: 0 !important;
}
.mh-6 {
    min-height: 6.25rem;
}
.pfs-1 {
    padding: .1rem .6rem .1rem .6rem; 
    font-size: .7rem;
}

.pfsm-1 {
    padding: .1rem .6rem .1rem .6rem; 
    font-size: .7rem;
    margin-top: 2px;
}

.fs-6 {
    font-size: .6rem;
}
.p17 {
    padding: .1rem .7rem .1rem .7rem;
}

.cp {
    cursor: pointer;
}
.m70 {
    max-height: 70vh;
}
.fz-7 {
    font-size: .7rem;
}

</style>